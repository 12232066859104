const datesAreDefined = process.env.GATSBY_OPEN_DATE && process.env.GATSBY_CLOSE_DATE

function applicationIsOpen() {
  //  if you pass a date value, new Date() returns a date object with hours set to 12:00 a.m.
  const openingDateTime = new Date(process.env.GATSBY_OPEN_DATE).toISOString()

  // closing time is set for 11:59pm CST. In the past we have set it to 6pm CST
  const closingDateTime = new Date(new Date(process.env.GATSBY_CLOSE_DATE).setHours(23, 59, 59, 999)).toISOString()

  // if you do not pass a date value, new Date() returns a date object representing the current date and time
  const currentDateTime = new Date().toISOString()

  return openingDateTime < currentDateTime && currentDateTime < closingDateTime
}

export const appsAreOpen = (datesAreDefined) ? applicationIsOpen() : true  // Default to open when open/close dates are not available.

// Determine the string to show the user for the open/close dates of the applications.
export let appStatusText
if (datesAreDefined) {
  if (appsAreOpen) {
    appStatusText = `Applications open through ${process.env.GATSBY_CLOSE_DATE}.`
  } else {
    if (new Date() < new Date(process.env.GATSBY_OPEN_DATE)) {
      appStatusText = `Applications open on ${process.env.GATSBY_OPEN_DATE}.`
    } else {
      appStatusText = `Applications closed on ${process.env.GATSBY_CLOSE_DATE}. Check back next year!`
    }
  }
} else {
  appStatusText = `Applications are open.`
}
