import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"

import {appsAreOpen, appStatusText} from "./dates"

const ApplicationLinkCard = props => {
  const featuredImage = (<Img fluid={props.applicationImage} className="card-img-top mb-3" />)
  return (
    <div className="mb-4">
      <div className="card mb-3">
        {appsAreOpen
          ?
            <Link to={props.applicationLink}>
              {featuredImage}
            </Link>
          :
            featuredImage
        }
        <div className="card-body">
          <h4 className="card-title">
            {props.applicationTitle}
          </h4>
          <p className="card-text">
            {props.applicationDescription}
          </p>
          {appsAreOpen
            ?
              <Link
                to={props.applicationLink}
                className="btn btn-primary link-btn"
                style={{color: "white"}}
              >
                Apply now
              </Link>
            :
              <button
                className="btn btn-primary link-btn"
                disabled
              >
                {appStatusText}
              </button>
          }
        </div>
      </div>
    </div>
  )
}

export default ApplicationLinkCard
