import React from "react"

import {appsAreOpen, appStatusText} from "./dates"

const PageTitle = props => (
    <div className="row">
      <div className="col-md-10 offset-md-1 text-center">
        <br />
        <h1>
          {props.titleText}
        </h1>
        {props.subtitleText ? <h6 className="text-muted">{props.subtitleText}</h6> : null}
        <hr />
      </div>
    </div>
)

export const ApplicationPageTitle = (props) => (
  <PageTitle titleText={props.titleText} subtitleText={(!appsAreOpen) ? appStatusText : null} />
)

export default PageTitle
